import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPolicy = () => (
    <Layout>
        <SEO title="Privacy Policy"/>
        <div className="container">
            <section className="py-5 mt-5">
                <article>
                    <h3>Privacy policy</h3>

                    <p>We respect your privacy and are committed to protecting it through our compliance with this
                        privacy
                        policy (“Policy”). This Policy describes the types of information we may collect from you or
                        that
                        you may provide (“Personal Information”) on the <a target="_blank" rel="nofollow"
                                                                           href="https://www.bliiink.co.uk">bliiink.co.uk</a> website
                        (“Website”), “Bliiink” mobile application (“Mobile Application”), and any of their related
                        products
                        and services (collectively, “Services”), and our practices for collecting, using, maintaining,
                        protecting, and disclosing that Personal Information. It also describes the choices available to
                        you
                        regarding our use of your Personal Information and how you can access and update it.</p>
                    <p>This Policy is a legally binding agreement between you (“User”, “you” or “your”) and Bliiink
                        (“Bliiink”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a
                        business or
                        other legal entity, you represent that you have the authority to bind such entity to this
                        agreement,
                        in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have
                        such
                        authority, or if you do not agree with the terms of this agreement, you must not accept this
                        agreement and may not access and use the Services. By accessing and using the Services, you
                        acknowledge that you have read, understood, and agree to be bound by the terms of this Policy.
                        This
                        Policy does not apply to the practices of companies that we do not own or control, or to
                        individuals
                        that we do not employ or manage.</p>
                    <h3>Automatic collection of information</h3>
                    <p>When you open the Website or use the Mobile Application, our servers automatically record
                        information
                        that your browser or device sends. This data may include information such as your device’s IP
                        address and location, browser and device name and version, operating system type and version,
                        language preferences, the webpage you were visiting before you came to the Services, pages of
                        the
                        Services that you visit, the time spent on those pages, the information you search for on the
                        Services, access times and dates, and other statistics.</p>
                    <p>Information collected automatically is used only to identify potential cases of abuse and
                        establish
                        statistical information regarding the usage and traffic of the Services. This statistical
                        information is not otherwise aggregated in such a way that would identify any particular User of
                        the
                        system.</p>
                    <h3>Collection of personal information</h3>
                    <p>You can access and use the Services without telling us who you are or revealing any information
                        by
                        which someone could identify you as a specific, identifiable individual. If, however, you wish
                        to
                        use some of the features offered on the Services, you may be asked to provide certain Personal
                        Information (for example, your name and e-mail address).</p>
                    <p>We receive and store any information you knowingly provide to us when you create an account,
                        publish
                        content, make a purchase, or fill any forms on the Services. When required, this information may
                        include the following:</p>
                    <ul>
                        <li>Account details (such as user name, unique user ID, password, etc)</li>
                        <li>Contact information (such as email address, phone number, etc)</li>
                        <li>Basic personal information (such as name, country of residence, etc)</li>
                        <li>Geolocation data of your device (such as latitude and longitude)</li>
                        <li>Certain features on the mobile device (such as contacts, calendar, gallery, etc)</li>
                        <li>Any other materials you willingly submit to us (such as articles, images, feedback, etc)
                        </li>
                    </ul>
                    <p>You can choose not to provide us with your Personal Information, but then you may not be able to
                        take
                        advantage of some of the features on the Services. Users who are uncertain about what
                        information is
                        mandatory are welcome to contact us.</p>
                    <h3>Privacy of children</h3>
                    <p>We do not knowingly collect any Personal Information from children under the age of 13. If you
                        are
                        under the age of 13, please do not submit any Personal Information through the Services. If you
                        have
                        reason to believe that a child under the age of 13 has provided Personal Information to us
                        through
                        the Services, please contact us to request that we delete that child’s Personal Information from
                        our
                        Services.</p>
                    <p>We encourage parents and legal guardians to monitor their children’s Internet usage and to help
                        enforce this Policy by instructing their children never to provide Personal Information through
                        the
                        Services without their permission. We also ask that all parents and legal guardians overseeing
                        the
                        care of children take the necessary precautions to ensure that their children are instructed to
                        never give out Personal Information when online without their permission.</p>
                    <h3>Use and processing of collected information</h3>
                    <p>We act as a data controller and a data processor in terms of the GDPR when handling Personal
                        Information, unless we have entered into a data processing agreement with you in which case you
                        would be the data controller and we would be the data processor.</p>
                    <p> Our role may also differ depending on the specific situation involving Personal Information. We
                        act
                        in the capacity of a data controller when we ask you to submit your Personal Information that is
                        necessary to ensure your access and use of the Services. In such instances, we are a data
                        controller
                        because we determine the purposes and means of the processing of Personal Information and we
                        comply
                        with data controllers’ obligations set forth in the GDPR.</p>
                    <p>We act in the capacity of a data processor in situations when you submit Personal Information
                        through
                        the Services. We do not own, control, or make decisions about the submitted Personal
                        Information,
                        and such Personal Information is processed only in accordance with your instructions. In such
                        instances, the User providing Personal Information acts as a data controller in terms of the
                        GDPR.</p>
                    <p>In order to make the Services available to you, or to meet a legal obligation, we may need to
                        collect
                        and use certain Personal Information. If you do not provide the information that we request, we
                        may
                        not be able to provide you with the requested products or services. Any of the information we
                        collect from you may be used for the following purposes:</p>
                    <ul>
                        <li>Create and manage user accounts</li>
                        <li>Fulfill and manage orders</li>
                        <li>Deliver products or services</li>
                        <li>Improve products and services</li>
                        <li>Send administrative information</li>
                        <li>Send marketing and promotional communications</li>
                        <li>Respond to inquiries and offer support</li>
                        <li>Request user feedback</li>
                        <li>Improve user experience</li>
                        <li>Post customer testimonials</li>
                        <li>Deliver targeted advertising</li>
                        <li>Administer prize draws and competitions</li>
                        <li>Enforce terms and conditions and policies</li>
                        <li>Protect from abuse and malicious users</li>
                        <li>Respond to legal requests and prevent harm</li>
                        <li>Run and operate the Services</li>
                    </ul>
                    <p>Processing your Personal Information depends on how you interact with the Services, where you are
                        located in the world and if one of the following applies: (i) you have given your consent for
                        one or
                        more specific purposes; this, however, does not apply, whenever the processing of Personal
                        Information is subject to California Consumer Privacy Act or European data protection law; (ii)
                        provision of information is necessary for the performance of an agreement with you and/or for
                        any
                        pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal
                        obligation to which you are subject; (iv) processing is related to a task that is carried out in
                        the
                        public interest or in the exercise of official authority vested in us; (v) processing is
                        necessary
                        for the purposes of the legitimate interests pursued by us or by a third party.</p>
                    <p> Note that under some legislations we may be allowed to process information until you object to
                        such
                        processing by opting out, without having to rely on consent or any other of the legal bases. In
                        any
                        case, we will be happy to clarify the specific legal basis that applies to the processing, and
                        in
                        particular whether the provision of Personal Information is a statutory or contractual
                        requirement,
                        or a requirement necessary to enter into a contract.</p>
                    <h3>Payment processing</h3>
                    <p>In case of Services requiring payment, you may need to provide your credit card details or other
                        payment account information, which will be used solely for processing payments. We use
                        third-party
                        payment processors (“Payment Processors”) to assist us in processing your payment information
                        securely.</p>
                    <p>Payment Processors adhere to the latest security standards as managed by the PCI Security
                        Standards
                        Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                        Sensitive and private data exchange happens over a SSL secured communication channel and is
                        encrypted and protected with digital signatures, and the Services are also in compliance with
                        strict
                        vulnerability standards in order to create as secure of an environment as possible for Users. We
                        will share payment data with the Payment Processors only to the extent necessary for the
                        purposes of
                        processing your payments, refunding such payments, and dealing with complaints and queries
                        related
                        to such payments and refunds.</p>
                    <p> Please note that the Payment Processors may collect some Personal Information from you, which
                        allows
                        them to process your payments (e.g., your email address, address, credit card details, and bank
                        account number) and handle all the steps in the payment process through their systems, including
                        data collection and data processing. The Payment Processors’ use of your Personal Information is
                        governed by their respective privacy policies which may or may not contain privacy protections
                        as
                        protective as this Policy. We suggest that you review their respective privacy policies.</p>
                    <h3>Managing information</h3>
                    <p>You are able to delete certain Personal Information we have about you. The Personal Information
                        you
                        can delete may change as the Services change. When you delete Personal Information, however, we
                        may
                        maintain a copy of the unrevised Personal Information in our records for the duration necessary
                        to
                        comply with our obligations to our affiliates and partners, and for the purposes described
                        below.</p>
                    <h3>Disclosure of information</h3>
                    <p> Depending on the requested Services or as necessary to complete any transaction or provide any
                        Service you have requested, we may share your information with our trusted subsidiaries and
                        joint
                        venture partners, affiliates, contracted companies, and service providers (collectively,
                        “Service
                        Providers”) we rely upon to assist in the operation of the Services available to you and whose
                        privacy policies are consistent with ours or who agree to abide by our policies with respect to
                        Personal Information. We will not share any personally identifiable information with third
                        parties
                        and will not share any information with unaffiliated third parties.</p>
                    <p>Service Providers are not authorized to use or disclose your information except as necessary to
                        perform services on our behalf or comply with legal requirements. Service Providers are given
                        the
                        information they need only in order to perform their designated functions, and we do not
                        authorize
                        them to use or disclose any of the provided information for their own marketing or other
                        purposes.</p>
                    <p>We may also disclose any Personal Information we collect, use or receive if required or permitted
                        by
                        law, such as to comply with a subpoena or similar legal process, and when we believe in good
                        faith
                        that disclosure is necessary to protect our rights, protect your safety or the safety of others,
                        investigate fraud, or respond to a government request.</p>
                    <p>In the event we go through a business transition, such as a merger or acquisition by another
                        company,
                        or sale of all or a portion of its assets, your user account, and your Personal Information will
                        likely be among the assets transferred.</p>
                    <h3>Retention of information</h3>
                    <p>We will retain and use your Personal Information for the period necessary to enforce our
                        agreements,
                        resolve disputes, and unless a longer retention period is required or permitted by law up to a
                        maximum of 60 months.</p>
                    <p>We may use any aggregated data derived from or incorporating your Personal Information after you
                        update or delete it, but not in a manner that would identify you personally. Once the retention
                        period expires, Personal Information shall be deleted. Therefore, the right to access, the right
                        to
                        erasure, the right to rectification, and the right to data portability cannot be enforced after
                        the
                        expiration of the retention period.</p>
                    <h3>Transfer of information</h3>
                    <p>Depending on your location, data transfers may involve transferring and storing your information
                        in a
                        country other than your own. However, this will not include countries outside the European Union
                        and
                        European Economic Area. If any such transfer takes place, you can find out more by checking the
                        relevant sections of this Policy or inquire with us using the information provided in the
                        contact
                        section.</p>
                    <h3>Data protection rights under the GDPR</h3>
                    <p>If you are a resident of the European Economic Area (“EEA”), you have certain data protection
                        rights
                        and we aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of
                        your
                        Personal Information. If you wish to be informed what Personal Information we hold about you and
                        if
                        you want it to be removed from our systems, please contact us. In certain circumstances, you
                        have
                        the following data protection rights:</p>
                    <p>
                        (i) You have the right to withdraw consent where you have previously given your consent to the
                        processing of your Personal Information. To the extent that the legal basis for our processing
                        of
                        your Personal Information is consent, you have the right to withdraw that consent at any time.
                        Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>
                    <p>(ii) You have the right to learn if your Personal Information is being processed by us, obtain
                        disclosure regarding certain aspects of the processing, and obtain a copy of your Personal
                        Information undergoing processing.</p>
                    <p>(iii) You have the right to verify the accuracy of your information and ask for it to be updated
                        or
                        corrected. You also have the right to request us to complete the Personal Information you
                        believe is
                        incomplete.</p>
                    <p>(iv) You have the right to object to the processing of your information if the processing is
                        carried
                        out on a legal basis other than consent. Where Personal Information is processed for the public
                        interest, in the exercise of an official authority vested in us, or for the purposes of the
                        legitimate interests pursued by us, you may object to such processing by providing a ground
                        related
                        to your particular situation to justify the objection. You must know that, however, should your
                        Personal Information be processed for direct marketing purposes, you can object to that
                        processing
                        at any time without providing any justification. To learn whether we are processing Personal
                        Information for direct marketing purposes, you may refer to the relevant sections of this
                        Policy.</p>
                    <p>(v) You have the right, under certain circumstances, to restrict the processing of your Personal
                        Information. These circumstances include: the accuracy of your Personal Information is contested
                        by
                        you and we must verify its accuracy; the processing is unlawful, but you oppose the erasure of
                        your
                        Personal Information and request the restriction of its use instead; we no longer need your
                        Personal
                        Information for the purposes of processing, but you require it to establish, exercise or defend
                        your
                        legal claims; you have objected to processing pending the verification of whether our legitimate
                        grounds override your legitimate grounds. Where processing has been restricted, such Personal
                        Information will be marked accordingly and, with the exception of storage, will be processed
                        only
                        with your consent or for the establishment, to exercise or defense of legal claims, for the
                        protection of the rights of another natural, or legal person or for reasons of important public
                        interest.</p>
                    <p>(vi) You have the right, under certain circumstances, to obtain the erasure of your Personal
                        Information from us. These circumstances include: the Personal Information is no longer
                        necessary in
                        relation to the purposes for which it was collected or otherwise processed; you withdraw consent
                        to
                        consent-based processing; you object to the processing under certain rules of applicable data
                        protection law; the processing is for direct marketing purposes; and the personal data have been
                        unlawfully processed. However, there are exclusions of the right to erasure such as where
                        processing
                        is necessary: for exercising the right of freedom of expression and information; for compliance
                        with
                        a legal obligation; or for the establishment, to exercise or defense of legal claims.</p>
                    <p>(vii) You have the right to receive your Personal Information that you have provided to us in a
                        structured, commonly used, and machine-readable format and, if technically feasible, to have it
                        transmitted to another controller without any hindrance from us, provided that such transmission
                        does not adversely affect the rights and freedoms of others.</p>
                    <p>(viii) You have the right to complain to a data protection authority about our collection and use
                        of
                        your Personal Information. If you are not satisfied with the outcome of your complaint directly
                        with
                        us, you have the right to lodge a complaint with your local data protection authority. For more
                        information, please contact your local data protection authority in the EEA. This provision is
                        applicable provided that your Personal Information is processed by automated means and that the
                        processing is based on your consent, on a contract which you are part of, or on pre-contractual
                        obligations thereof.</p>
                    <h3>California privacy rights</h3>
                    <p>Consumers residing in California are afforded certain additional rights with respect to their
                        Personal Information under the California Consumer Privacy Act (“CCPA”). If you are a California
                        resident, this section applies to you.</p>
                    <p>In addition to the rights as explained in this Policy, California residents who provide Personal
                        Information as defined in the statute to obtain Services for personal, family, or household use
                        are
                        entitled to request and obtain from us, once a calendar year, information about the categories
                        and
                        specific pieces of Personal Information we have collected and disclosed.</p>
                    <p>Furthermore, California residents have the right to request deletion of their Personal
                        Information or
                        opt-out of the sale of their Personal Information which may include selling, disclosing, or
                        transferring Personal Information to another business or a third party for monetary or other
                        valuable consideration. To do so, simply contact us. We will not discriminate against you if you
                        exercise your rights under the CCPA.</p>
                    <h3>How to exercise your rights</h3>
                    <p>Any requests to exercise your rights can be directed to us through the contact details provided
                        in
                        this document. Please note that we may ask you to verify your identity before responding to such
                        requests. Your request must provide sufficient information that allows us to verify that you are
                        the
                        person you are claiming to be or that you are the authorized representative of such person. If
                        we
                        receive your request from an authorized representative, we may request evidence that you have
                        provided such an authorized representative with power of attorney or that the authorized
                        representative otherwise has valid written authority to submit requests on your behalf.</p>
                    <p>You must include sufficient details to allow us to properly understand the request and respond to
                        it.
                        We cannot respond to your request or provide you with Personal Information unless we first
                        verify
                        your identity or authority to make such a request and confirm that the Personal Information
                        relates
                        to you.</p>
                    <h3>Cookies</h3>
                    <p>Our Services use “cookies” to help personalize your online experience. A cookie is a text file
                        that
                        is placed on your hard disk by a web page server. Cookies cannot be used to run programs or
                        deliver
                        viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web
                        server
                        in the domain that issued the cookie to you. You may learn more about cookies and how they
                        work <a
                            target="_blank" href="https://www.websitepolicies.com/blog/cookies">here</a>.</p>
                    <p>We may use cookies to collect, store, and track information for security and personalization, and
                        for
                        statistical purposes. Please note that you have the ability to accept or decline cookies. Most
                        web
                        browsers automatically accept cookies by default, but you can modify your browser settings to
                        decline cookies if you prefer.</p>
                    <h3>Do Not Track signals</h3>
                    <p>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do
                        not
                        want to have your online activity tracked. Tracking is not the same as using or collecting
                        information in connection with a website. For these purposes, tracking refers to collecting
                        personally identifiable information from consumers who use or visit a website or online service
                        as
                        they move across different websites over time. The Services do not track its visitors over time
                        and
                        across third-party websites. However, some third-party websites may keep track of your browsing
                        activities when they serve you content, which enables them to tailor what they present to
                        you.</p>
                    <h3>Advertisements</h3>
                    <p>We may display online advertisements and we may share aggregated and non-identifying information
                        about our customers that we or our advertisers collect through your use of the Services. We do
                        not
                        share personally identifiable information about individual customers with advertisers. In some
                        instances, we may use this aggregated and non-identifying information to deliver tailored
                        advertisements to the intended audience.</p>
                    <p>We may also permit certain third-party companies to help us tailor advertising that we think may
                        be
                        of interest to Users and to collect and use other data about User activities on the Services.
                        These
                        companies may deliver ads that might place cookies and otherwise track User behavior.</p>
                    <h3>Social media features</h3>
                    <p>Our Services may include social media features, such as the Facebook and Twitter buttons, Share
                        This
                        buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect
                        your
                        IP address, what page you are visiting on our Services, and may set a cookie to enable Social
                        Media
                        Features to function properly. Social Media Features are hosted either by their respective
                        providers
                        or directly on our Services. Your interactions with these Social Media Features are governed by
                        the
                        privacy policy of their respective providers.</p>
                    <h3>Email marketing</h3>
                    <p>We offer electronic newsletters to which you may voluntarily subscribe at any time. We are
                        committed
                        to keeping your e-mail address confidential and will not disclose your email address to any
                        third
                        parties except as allowed in the information use and processing section or for the purposes of
                        utilizing a third-party provider to send such emails. We will maintain the information sent via
                        e-mail in accordance with applicable laws and regulations.</p>
                    <p>In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail
                        is
                        from and provide clear information on how to contact the sender. You may choose to stop
                        receiving
                        our newsletter or marketing emails by following the unsubscribe instructions included in these
                        emails or by contacting us. However, you will continue to receive essential transactional
                        emails.</p>
                    <h3>Affiliate links</h3>
                    <p>We may engage in affiliate marketing and have affiliate links present on the Services for the
                        purpose
                        of being able to offer you related or additional products and services. If you click on an
                        affiliate
                        link, a cookie will be placed on your browser to track any sales for purposes of
                        commissions.</p>
                    <h3>Links to other resources</h3>
                    <p>The Services contain links to other resources that are not owned or controlled by us. Please be
                        aware
                        that we are not responsible for the privacy practices of such other resources or third parties.
                        We
                        encourage you to be aware when you leave the Services and to read the privacy statements of each
                        and
                        every resource that may collect Personal Information.</p>
                    <h3>Information security</h3>
                    <p>We secure information you provide on computer servers in a controlled, secure environment,
                        protected
                        from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical,
                        and
                        physical safeguards in an effort to protect against unauthorized access, use, modification, and
                        disclosure of Personal Information in our control and custody. However, no data transmission
                        over
                        the Internet or wireless network can be guaranteed.</p>
                    <p>Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there
                        are
                        security and privacy limitations of the Internet which are beyond our control; (ii) the
                        security,
                        integrity, and privacy of any and all information and data exchanged between you and the
                        Services
                        cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in
                        transit by a third party, despite best efforts.</p>
                    <p>As the security of Personal Information depends in part on the security of the device you use to
                        communicate with us and the security you use to protect your credentials, please take
                        appropriate
                        measures to protect this information.</p>
                    <h3>Data breach</h3>
                    <p>In the event we become aware that the security of the Services has been compromised or Users’
                        Personal Information has been disclosed to unrelated third parties as a result of external
                        activity,
                        including, but not limited to, security attacks or fraud, we reserve the right to take
                        reasonably
                        appropriate measures, including, but not limited to, investigation and reporting, as well as
                        notification to and cooperation with law enforcement authorities. In the event of a data breach,
                        we
                        will make reasonable efforts to notify affected individuals if we believe that there is a
                        reasonable
                        risk of harm to the User as a result of the breach or if notice is otherwise required by law.
                        When
                        we do, we will send you an email.</p>
                    <h3>Changes and amendments</h3>
                    <p>We reserve the right to modify this Policy or its terms related to the Services at any time at
                        our
                        discretion. When we do, we will revise the updated date at the bottom of this page. We may also
                        provide notice to you in other ways at our discretion, such as through the contact information
                        you
                        have provided.</p>
                    <p>An updated version of this Policy will be effective immediately upon the posting of the revised
                        Policy unless otherwise specified. Your continued use of the Services after the effective date
                        of
                        the revised Policy (or such other act specified at that time) will constitute your consent to
                        those
                        changes. However, we will not, without your consent, use your Personal Information in a manner
                        materially different than what was stated at the time your Personal Information was
                        collected.</p>
                    <h3>Acceptance of this policy</h3>
                    <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By
                        accessing and using the Services and submitting your information you agree to be bound by this
                        Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to
                        access
                        or use the Services.</p>
                    <h3>Contacting us</h3>
                    <p>If you have any questions, concerns, or complaints regarding this Policy, the information we hold
                        about you, or if you wish to exercise your rights, we encourage you to contact us using the
                        details
                        below:</p>
                    <p>info@bliiink.co.uk</p>
                    <p>We will attempt to resolve complaints and disputes and make every reasonable effort to honor your
                        wish to exercise your rights as quickly as possible and in any event, within the timescales
                        provided
                        by applicable data protection laws.</p>
                    <p>This document was last updated on February 21, 2021</p>

                </article>
            </section>
        </div>
    </Layout>
)


export default PrivacyPolicy
